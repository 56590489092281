import React from "react";
import { string } from "prop-types";
import CtaPrimary from "../Buttons/primary-cta";
import GatedForm from "./GatedForm";
import GatedStyles from "./GatedStyles";
import Image from "../ImageQuerys/LargeFeaturesImages";
import Heading from "../../components-v2/Base/HeadingBuilder";

const GatedCTA = (props) => {
  const { ctaTitle, ctaBtnTitle, ctaBtnTarget } = props;
  return (
    <div className="gated-cta">
      <Heading
        symbol="."
        withBlueSymbol
        injectionType={2}
        level={2}
        className="gated-title"
      >
        {ctaTitle}
      </Heading>
      <CtaPrimary ctaTitle={ctaBtnTitle} target={ctaBtnTarget} />
      <Image className="gated-cta-img" imageName="gated-cta-bg.png" />
      <GatedStyles />
    </div>
  );
};

GatedCTA.propTypes = {
  ctaTitle: string,
  ctaBtnTitle: string,
  ctaBtnTarget: string,
};

GatedCTA.defaultProps = {
  ctaTitle: "Learn more by scheduling your FREE Demo",
  ctaBtnTitle: "Learn more",
  ctaBtnTarget:
    "/demo?utm_source=gated_assets&utm_medium=referral&utm_campaign=gated_assets",
};

export default GatedCTA;
